import { writable } from 'svelte/store'

var auth2

//const CLIENT_ID = import.meta.env.VITE_APP_CLIENT_ID
const CLIENT_ID = '926937695002-kfi6kdvui8ibtk1d7ees09gjts2841vp.apps.googleusercontent.com'
const { subscribe, set, update } = writable(null)

if (!gapi) { throw new Error('Google API is not available! https://apis.google.com/js/platform.js') }

gapi.load('auth2', () => {
  auth2 = gapi.auth2.init({
    client_id: CLIENT_ID,
    scope: 'profile'
  })

  auth2.isSignedIn.listen((loggedIn) => {
    if (loggedIn) {
      const u = auth2.currentUser.get()
      const profile = u.getBasicProfile()
      update(() => ({
        profile: {
          id: profile.getId(),
          name: profile.getName(),
          image: profile.getImageUrl(),
          email: profile.getEmail()
        },
        token: u.getAuthResponse().id_token
      }))
    } else {
      update(() => null)
    }
  })
})

const signin = () => auth2 ? auth2.signIn() : false
const logout = () => auth2 ? auth2.signOut() : false

export const user = {
  subscribe,
  signin,
  logout
}
