<script>
  import Shell from "./Shell.svelte";
  import { accounts, getDoc, postDoc, putDoc } from "./api.js";
  import { router } from "tinro";

  let editMode = false;
  let id,
    type,
    title,
    blob,
    state = "new",
    account = localStorage.getItem("account"),
    source,
    faq,
    error;

  const url = import.meta.env.VITE_APP_API;

  if (router.meta().pattern === "/documents/:id/edit") {
    // edit mode
    editMode = true;
    id = router.meta().params.id;
    getDoc(url, id)
      .then((d) => (console.log(d), d))
      .then((doc) => {
        id = doc.id;
        type = doc.type;
        title = doc.Title;
        blob = doc.Blob;
        state = doc.State;
        account = doc.Attributes.account;
        source = doc.Attributes._source_uri;
        faq = doc.Attributes.faq ? 1 : -1;
      });
    // get account
    /*
    setTimeout(() => 
    fetch(url + '/docs/' + id)
      .then(res => res.json())
      .then(doc => {
        id = doc.id
        type = doc.type
        title = doc.Title
        blob = doc.Blob
        state = doc.State
        account = doc.Attributes.account
        source = doc.Attributes._source_uri
      })
      , 200)
    */
  }

  // TODO: need to refactor object to include source and account information
  const handleSubmit = () => {
    if (editMode) {
      putDoc(url, id, {
        id: id,
        type: type,
        Title: title,
        Blob: blob,
        ContentType: "PLAIN_TEXT",
        Attributes: {
          _source_uri: source,
          account: account,
          faq: faq === 1,
        },
        State: state,
        History: [],
      }).then((res) => {
        if (res.ok) return router.goto("/documents");
        error = true;
        console.log(res.message);
      });
    } else {
      postDoc(url, {
        Title: title,
        Blob: blob,
        ContentType: "PLAIN_TEXT",
        Attributes: {
          _source_uri: source,
          account: account,
          faq: faq === 1,
        },
        State: "index",
        History: [],
      }).then((res) => {
        if (res.ok) return router.goto("/documents");
        error = true;
        console.log(res.message);
      });
    }
  };
</script>

<Shell title="Document Form">
  <form
    on:submit|preventDefault={handleSubmit}
    class="space-y-8 divide-y divide-gray-200"
  >
    <div class="space-y-8 divide-y divide-gray-200">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Citibot Search Document
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            A search document contains a Title and Content, the title is uses to
            identify the search when a question is asked to the search engine.
          </p>
        </div>
        {#if error}
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: x-circle -->
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  An error occured trying to save form.
                </h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul class="list-disc pl-5 space-y-1">
                    <li>Title is required!</li>
                    <li>Content is required!</li>
                    <li>Account is required!</li>
                    <li>Source is required!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="name" class="block text-sm font-medium text-gray-700">
              Title
            </label>
            <div class="mt-1">
              <input
                bind:value={title}
                id="name"
                name="name"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-6">
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
            >
              Content
            </label>
            <div class="mt-1">
              <textarea
                bind:value={blob}
                id="description"
                name="description"
                rows="25"
                class="shadow-sm focus:ring-indigo-500 focus:border-citibot block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <p class="mt-2 text-sm text-gray-500">Account Description</p>
          </div>
          <div class="sm:col-span-4">
            <label
              for="account"
              class="block text-sm font-medium text-gray-700"
            >
              Account
            </label>
            {#if editMode}
              {account}
            {:else}
              <div class="mt-1">
                <select
                  bind:value={account}
                  class="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  id="account"
                  name="account"
                >
                  <option>Select Account</option>
                  {#await accounts(url) then accounts}
                    {#each accounts as account}
                      <option value={account.identifier}>{account.name}</option>
                    {/each}
                  {/await}
                </select>
              </div>
            {/if}
          </div>
          <div class="sm:col-span-6">
            <label for="source" class="block text-sm font-medium text-gray-700">
              Source (url)
            </label>
            <div class="mt-1 w-full">
              <input
                bind:value={source}
                id="source"
                name="source"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-4">
            <label for="source" class="block text-sm font-medium text-gray-700">
              FAQ
            </label>
            <div class="mt-1">
              <select bind:value={faq}>
                <option value={1}>True</option>
                <option value={-1}>False</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <a
          href="/documents"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Cancel
        </a>
        <button
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</Shell>
