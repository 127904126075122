<script>
  export let results  
</script>
<!-- This example requires Tailwind CSS v2.0+ -->
<ul class="divide-y divide-gray-200">
  {#each results as result}
  <li class="py-4">
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="flex">
      <div>
        <h4 class="text-lg font-bold">{result.title}</h4>
        <p class="mt-1">
          {result.body}
        </p>
        <p>
        <a class="text-citibot" href="{result.url}">[{result.url}]</a>
        </p>
      </div>
    </div>

  </li>
  {/each}

</ul>

