<script>
  import Shell from "./Shell.svelte";
  import { getJob, crawl, deleteJob } from "./api.js";
  import { router } from "tinro";

  let id = router.meta().params.id;
  let running = false;

  const url = import.meta.env.VITE_APP_API;

  const launch = () =>
    crawl(url, id).then((res) => {
      if (res.ok) {
        running = true;
      }
    });

  const job = () => getJob(url, id).then((res) => (console.log(res), res));

  const handleDelete = (e) => {
    if (confirm("Are you sure?")) {
      //fetch(url + "/jobs/" + id, { method: "DELETE" })
      deleteJob(url, id)
        .then((r) => router.goto("/jobs"))
        .catch((e) => console.log(e));
    }
  };
</script>

<Shell title="Job">
  {#await job() then job}
    <div class="mt-5 mb-5 relative">
      <h1 class="text-lg leading-6 font-medium text-gray-900">
        {job.Description}
      </h1>
      <div class="mt-5">
        <div class="font-medium">Website</div>
        <h3 class="mb-5">{job.Website}</h3>
      </div>
      <div class="mt-5">
        <div class="font-medium">Script</div>
        <pre
          class="border rounded-md p-4 my-8 bg-black"><code class="language-javascript">{window.atob(job.script)}</code></pre>
      </div>
      {#if running}
        <div>[Job Process is running...]</div>
      {:else}
        <button
          class="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
          on:click={launch}>Launch Manually</button
        >
      {/if}
      <div class="mt-5">
        <h3 class="font-medium">History</h3>
        <table class="min-w-full divide-y divide-gray-200">
          <thead class="bg-gray-50">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Status
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
              >
                Details
              </th>
            </tr>
          </thead>
          <tbody>
            {#each job.History as hx, i}
              <tr class={i % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {new Date(hx.date).toLocaleDateString()} - {new Date(
                    hx.date
                  ).toLocaleTimeString()}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {hx.status}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                >
                  {hx.msg}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>

        <div class="mt-5">
          <a
            href="/jobs/{id}/edit"
            class="border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-2 md:text-lg md:px-10"
          >
            Edit Job
          </a>
          <button
            on:click|preventDefault={handleDelete}
            class="border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-1 md:text-lg md:px-10"
          >
            Delete Job
          </button>
        </div>
      </div>
    </div>
  {/await}
</Shell>
