<script>
  import Shell from './Shell.svelte'
  import { router } from 'tinro'

  const url = import.meta.env.VITE_APP_API
  const id = router.meta().params.id

  const getDoc = () => fetch(url + '/docs/' + id)
      .then(res => res.json())
      

  const approve = () => fetch(url + '/docs/' + id + '/_approve', { method: 'POST'})
    .then(res => res.json())
    .then(res => {
      if (res.ok) {
        router.goto('/documents')
      }
      // TODO: handle error case
    })

  const deny = () => fetch(url + '/docs/' + id + '/_deny', { method: 'POST'})
    .then(res => res.json())
    .then(res => {
      if (res.ok) {
        router.goto('/documents')
        // handle error case
      }
    })
</script>
<Shell title="Document Review">
  {#await getDoc()}
  <p>Loading Document...</p>
  {:then doc}
  <p class="mt-5 font-medium">Review Document for inclusion into the the search engine.</p>
  <div class="mt-3">
    <p>Title/Search Criteria: </p>
    <h3 class="mb-3 font-medium text-lg">{doc.Title}</h3>
    <label>Content/Search Result</label>
    <p class="mb-3 font-medium text-lg">{doc.Blob}</p>
    
    <p>Clickable Link: {doc.Attributes._source_uri}</p>
    <p>Account: {doc.Attributes.account}</p>
    
    <div class="mt-3">
      <button class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot" on:click={approve} type="button">Approve</button>
      <button class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot" on:click={deny} type="button">Deny</button>
      <a class="ml-3 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot" href="/documents">Cancel</a>
    </div>

  </div>
  {:catch error}
  <p style="color: red">ERROR: {error.message}</p>
  {/await}
</Shell>