<script>
  import Shell from "./Shell.svelte";
  import { logs } from "./api.js";
  import { format, subDays } from "date-fns";

  const url = import.meta.env.VITE_APP_API;
  // labels
  const labels = ["CRAWLER", "PUBLISH", "DOC", "JOB", "ACCOUNT"];
  // statuses
  const [ALL, INFO, WARNING, ERROR, DEBUG] = [
    "ALL",
    "INFO",
    "WARNING",
    "ERROR",
    "DEBUG",
  ];
  const STATUSES = [ALL, INFO, WARNING, ERROR, DEBUG];
  let loading = false;
  let data;
  let days = "1";
  let status = ALL;
  let label = "ANY";

  function handleSubmit() {
    loading = true;
    let date = format(subDays(new Date(), Number(days)), "yyyy-MM-dd");
    logs(url, date, status, label).then((res) => {
      data = res;
      loading = false;
    });
  }
</script>

<Shell title="Logs">
  <blockquote class="mt-8 mb-4 text-gray-400">Log Report criteria.</blockquote>
  <div class="flex items-center">
    <form on:submit|preventDefault={handleSubmit}>
      <label for="days" class="mx-2">Report Range</label>
      <select id="days" bind:value={days}>
        <option value="1">Yesterday</option>
        <option value="7">Last 7 Days</option>
      </select>
      <label for="label" class="mx-2">Label</label>
      <select id="label" bind:value={label}>
        <option value={"ANY"}>ANY</option>
        {#each labels as label}
          <option value={label}>{label}</option>
        {/each}
      </select>
      <label for="status" class="mx-2">Status</label>
      <select id="status" bind:value={status}>
        {#each STATUSES as STATUS}
          <option value={STATUS}>{STATUS}</option>
        {/each}
      </select>
      <button class="ml-2 border rounded-md bg-citibot text-white px-4 py-2"
        >Run Report</button
      >
    </form>
  </div>
  {#if loading}
    <div>Loading...</div>
  {/if}
  {#if data && !loading}
    <h3 class="mt-8 text-2xl">Report Data</h3>
    <table class="mt-4 min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Date
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
          >
            Label
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
          >
            Status
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
          >
            Message
          </th>
        </tr>
      </thead>
      <tbody>
        {#each data.docs.reverse() as record}
          <tr>
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >{record.date}</td
            >
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >{record.label}</td
            >
            <td
              class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
              >{record.status}</td
            >
            <td class="px-6 py-4 text-sm font-medium text-gray-900"
              >{record.message}</td
            >
          </tr>
        {/each}
      </tbody>
    </table>
  {/if}
</Shell>
