<script>
  import MenuItem from "./components/MenuItem.svelte";
  import { user } from "./auth.js";

  export let current = "dashboard";

  const isCurrent = (page) => (current === page ? "current" : "default");
  const isCurrentSvg = (page) =>
    current === page ? "currentsvg" : "defaultsvg";

  const toggleClasses = (test, a, b) => (test ? a : b);

  const smMenuItem = (page) =>
    toggleClasses(
      current === page,
      "bg-gray-100 text-gray-900",
      "text-gray-600 hover:bg-gray-50 text-gray-900"
    );
</script>

<!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
<div class="md:hidden">
  <div class="fixed inset-0 flex z-40">
    <!--
      Off-canvas menu overlay, show/hide based on off-canvas menu state.

      Entering: "transition-opacity ease-linear duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "transition-opacity ease-linear duration-300"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <div class="fixed inset-0">
      <div class="absolute inset-0 bg-gray-600 opacity-75" />
    </div>
    <!--
      Off-canvas menu, show/hide based on off-canvas menu state.

      Entering: "transition ease-in-out duration-300 transform"
        From: "-translate-x-full"
        To: "translate-x-0"
      Leaving: "transition ease-in-out duration-300 transform"
        From: "translate-x-0"
        To: "-translate-x-full"
    -->
    <div class="relative flex-1 flex flex-col max-w-xs w-full bg-white">
      <div class="absolute top-0 right-0 -mr-12 pt-2">
        <button
          class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
        >
          <span class="sr-only">Close sidebar</span>
          <!-- Heroicon name: x -->
          <svg
            class="h-6 w-6 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>
      <div class="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
        <div class="flex-shrink-0 flex items-center px-4">
          <a href="/">
            <img class="h-8 w-auto" src="/citibot-logo.png" alt="Citibot" />
          </a>
        </div>
        <nav class="mt-5 px-2 space-y-1">
          <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" -->
          <MenuItem
            active={current === "dashboard"}
            text="dashboard"
            href="/dashboard"
          />
          <MenuItem active={current === "jobs"} text="jobs" href="/jobs" />
          <MenuItem
            active={current === "documents"}
            text="documents"
            href="/documents"
          />
          <!--
          <MenuItem active={current === 'reports'} text="reports" href="/reports" />
          -->
          <MenuItem
            active={current === "accounts"}
            text="accounts"
            href="/accounts"
          />
        </nav>
      </div>
      <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
        <a href="#" class="flex-shrink-0 group block">
          <div class="flex items-center">
            <div>
              {#if $user}
                <img
                  class="inline-block h-10 w-10 rounded-full"
                  src={$user.image}
                  alt=""
                />
              {/if}
            </div>
            <div class="ml-3">
              <p
                class="text-base font-medium text-gray-700 group-hover:text-gray-900"
              >
                {#if $user}
                  {$user.name}
                {:else}
                  Not Logged In
                {/if}
              </p>
              <p
                class="text-sm font-medium text-gray-500 group-hover:text-gray-700"
              >
                {#if $user}
                  <a href="#" on:click={() => user.logout()}>Logout</a>
                {:else}
                  <a href="#" on:click={() => user.sigin()}>Log In</a>
                {/if}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="flex-shrink-0 w-14">
      <!-- Force sidebar to shrink to fit close icon -->
    </div>
  </div>
</div>

<!-- Static sidebar for desktop -->
<div class="hidden md:flex md:flex-shrink-0">
  <div class="flex flex-col w-64">
    <!-- Sidebar component, swap this element with another sidebar if you like -->
    <div class="flex flex-col h-0 flex-1 border-r border-gray-200 bg-white">
      <div class="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
        <div class="flex items-center flex-shrink-0 px-4">
          <a href="/">
            <img class="h-8 w-auto" src="/citibot-logo.png" alt="Citibot" />
          </a>
        </div>
        <nav class="mt-5 flex-1 px-2 bg-white space-y-1">
          <MenuItem
            active={current === "dashboard"}
            text="dashboard"
            href="/dashboard"
          />
          <MenuItem active={current === "jobs"} text="jobs" href="/jobs" />
          <MenuItem
            active={current === "documents"}
            text="documents"
            href="/documents"
          />
          <MenuItem active={current === "logs"} text="logs" href="/logs" />
          <MenuItem
            active={current === "accounts"}
            text="accounts"
            href="/accounts"
          />
        </nav>
      </div>
      <div class="flex-shrink-0 flex border-t border-gray-200 p-4">
        <a href="#" class="flex-shrink-0 w-full group block">
          <div class="flex items-center">
            <div>
              {#if $user}
                <img
                  class="inline-block h-10 w-10 rounded-full"
                  src={$user.profile.image}
                  alt=""
                />
              {/if}
            </div>
            <div class="ml-3">
              <p
                class="text-base font-medium text-gray-700 group-hover:text-gray-900"
              >
                {#if $user}
                  {$user.profile.name}
                {:else}
                  Not Logged In
                {/if}
              </p>
              <p
                class="text-sm font-medium text-gray-500 group-hover:text-gray-700"
              >
                {#if $user}
                  <a href="#" on:click={() => user.logout()}>Logout</a>
                {:else}
                  <a href="#" on:click={() => user.sigin()}>Log In</a>
                {/if}
              </p>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>
