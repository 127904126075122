<script>
  import Shell from "./Shell.svelte";
  import { router } from "tinro";
  import { accounts } from "./api.js";

  const url = import.meta.env.VITE_APP_API;

  function handleView(id) {
    return function () {
      router.goto("/accounts/" + id);
    };
  }
</script>

<Shell title="Accounts">
  {#await accounts(url)}
    <div>Loading...</div>
  {:then accounts}
    <div class="mt-5 relative">
      <p>Setup citibot accounts for citibot search</p>
      <div class="text-right rounded md-shadow">
        <a
          class="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
          href="/accounts/new">Add New Account</a
        >
      </div>
    </div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="mt-5 flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Identifier
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {#each accounts as account, i}
                  <!-- Odd row -->
                  <tr
                    class="hover:bg-gray-100 cursor-pointer"
                    on:click={handleView(account.id)}
                  >
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {account.name}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {account.identifier}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {account.description}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <a
                        href="/accounts/{account.id}/edit"
                        class="text-indigo-600 hover:text-indigo-900">Edit</a
                      >
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  {:catch error}
    <div>An Error Occured trying to access the api</div>
  {/await}
</Shell>
