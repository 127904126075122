<script>
  import Shell from "./Shell.svelte";
  import { user } from "./auth.js";
  import { jobs } from "./api.js";

  const url = import.meta.env.VITE_APP_API;
</script>

<Shell title="Jobs">
  {#await jobs(url)}
    <div>Loading...</div>
  {:then jobs}
    <div class="mt-5 relative">
      <p>
        Use the jobs page to manage job tasks that can crawl a website. These
        jobs can be kicked off manually now.
      </p>
      <div class="text-right rounded md-shadow">
        <a
          class="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
          href="/jobs/new">Add New Job</a
        >
      </div>
    </div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="mt-5 flex flex-col">
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
          >
            <table class="min-w-full divide-y divide-gray-200">
              <thead class="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Description
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                  >
                    Website
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    State
                  </th>
                  <th scope="col" class="relative px-6 py-3">
                    <span class="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {#each jobs as job, i}
                  <!-- Odd row -->
                  <tr class={i % 2 === 0 ? "bg-gray-50" : "bg-white"}>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                    >
                      {job.Description}
                    </td>
                    <td class="px-6 py-4 text-sm text-gray-500">
                      {job.Website}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                    >
                      {job.State}
                    </td>
                    <td
                      class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium"
                    >
                      <a
                        href="/jobs/{job.id}"
                        class="text-indigo-600 hover:text-indigo-900">View</a
                      >
                    </td>
                  </tr>
                {/each}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  {/await}
</Shell>
