<script>
  import Shell from "./Shell.svelte";
  import { getAccount, postAccount, putAccount } from "./api.js";
  import { router } from "tinro";
  const url = import.meta.env.VITE_APP_API;

  let id, name, identifier, description, storage, error;
  let editMode = false;

  if (router.meta().pattern === "/accounts/:id/edit") {
    id = router.meta().params.id;
    // edit mode
    editMode = true;
    // get account
    getAccount(url, id).then((account) => {
      console.log(account);
      id = account.id;
      name = account.name;
      identifier = account.identifier;
      description = account.description;
      storage = "";
    });
  }
  const handleSubmit = () => {
    if (editMode) {
      return putAccount(url, id, {
        type: "ACCOUNT",
        id: id,
        name: name,
        description: description,
        identifier: identifier,
        storage: "",
      }).then((res) => {
        if (res.ok) return router.goto("/accounts");
        error = true;
        console.log(res.message);
      });
    } else {
      return postAccount(url, { name, identifier, description, storage }).then(
        (res) => {
          if (res.ok) return router.goto("/accounts");
          error = true;
          console.log(res.message);
        }
      );
    }
  };
</script>

<Shell title="Account Form">
  <form
    on:submit|preventDefault={handleSubmit}
    class="space-y-8 divide-y divide-gray-200"
  >
    <div class="space-y-8 divide-y divide-gray-200">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Citibot Search Account
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            A citibot search account classifies each search document with a
            specific account.
          </p>
        </div>
        {#if error}
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: x-circle -->
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  An error occured trying to save form.
                </h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul class="list-disc pl-5 space-y-1">
                    <li>Name is required!</li>
                    <li>Identifier is required!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label for="name" class="block text-sm font-medium text-gray-700">
              Name
            </label>
            <div class="mt-1">
              <input
                bind:value={name}
                id="name"
                name="name"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-4">
            <label
              for="identifier"
              class="block text-sm font-medium text-gray-700"
            >
              Identifier
            </label>
            <div class="mt-1">
              <input
                bind:value={identifier}
                id="identifier"
                name="identifier"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>

          <div class="sm:col-span-6">
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <div class="mt-1">
              <textarea
                bind:value={description}
                id="description"
                name="description"
                rows="3"
                class="shadow-sm focus:ring-indigo-500 focus:border-citibot block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
            <p class="mt-2 text-sm text-gray-500">Account Description</p>
          </div>
          <!--

       
        <div class="sm:col-span-4">
          <label for="storage" class="block text-sm font-medium text-gray-700">
            Storage 
          </label>
          <div class="mt-1">
            <input bind:value={storage} id="storage" name="storage" type="text" autocomplete="off" class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md">
          </div>
        </div>
         -->
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <a
          href="/accounts"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Cancel
        </a>
        <button
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</Shell>
