<script>
  import Shell from "./Shell.svelte";
  import Stat from "./components/Stat.svelte";
  import Search from "./components/Search.svelte";
  import SearchResults from "./components/SearchResults.svelte";
  import { user } from "./auth.js";
  import { accounts, search } from "./api.js";

  const url = import.meta.env.VITE_APP_API;

  let results;

  function doSearch(e) {
    e.preventDefault();
    search(url, e.detail.q, e.detail.account, e.detail.faq)
      .then((res) => (results = res.matches))
      .catch((e) => {
        console.log(e);
        alert("An error occured!");
      });
  }
</script>

<Shell title="Dashboard">
  <div class="mt-5 mb-5 relative">
    <div class="absolute inset-0 flex items-center" aria-hidden="true">
      <div class="w-full border-t border-gray-300" />
    </div>
    <div class="relative flex justify-center">
      <span class="px-2 bg-white text-sm text-gray-500">
        Ask Citibot Search
      </span>
    </div>
  </div>
  {#await accounts(url) then accounts}
    <Search on:search={doSearch} {accounts} />
  {:catch error}
    <p>An error maybe try to login again via <a href="/">Dashboard</a></p>
  {/await}
  {#if results}
    <div class="mt-5 mb-5 relative">
      <div class="absolute inset-0 flex items-center" aria-hidden="true">
        <div class="w-full border-t border-gray-300" />
      </div>
      <div class="relative flex justify-center">
        <span class="px-2 bg-white text-sm text-gray-500">
          Citibot Search Results
        </span>
      </div>
    </div>
    <SearchResults {results} />
    <button
      class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
      on:click={() => (results = null)}>New Search</button
    >
  {/if}
</Shell>
