<script>
  import Shell from "./Shell.svelte";

  import { accounts, getJob, postJob, putJob } from "./api.js";
  import { router } from "tinro";

  let editMode = false;
  let id,
    search,
    state,
    description,
    website,
    account,
    error,
    frequency,
    script,
    history;
  const url = import.meta.env.VITE_APP_API;

  if (router.meta().pattern === "/jobs/:id/edit") {
    editMode = true;
    id = router.meta().params.id;
    getJob(url, id).then((doc) => {
      id = doc.id;
      description = doc.Description;
      search = doc.Search;
      state = doc.State;
      account = doc.account;
      script = atob(doc.script);
      frequency = doc.frequency;
      history = doc.History;
      website = doc.Website;
    });
  }

  const handleSubmit = () => {
    if (editMode) {
      putJob(url, id, {
        id: id,
        type: "JOB",
        Description: description,
        Website: website,
        Search: search,
        State: "READY",
        account: account,
        script: btoa(script),
        frequency: frequency,
        History: history,
      }).then((res) => {
        if (res.ok) return router.goto("/jobs");
        error = true;
        console.log(res.message);
      });
    } else {
      postJob(url, {
        Description: description,
        Website: website,
        Search: search,
        State: "READY",
        account: account,
        script: btoa(script),
        frequency: frequency,
        History: [],
      }).then((res) => {
        if (res.ok) return router.goto("/jobs");
        error = true;
        console.log(res.message);
      });
    }
  };
</script>

<Shell title="Job Form">
  <form
    on:submit|preventDefault={handleSubmit}
    class="space-y-8 divide-y divide-gray-200"
  >
    <div class="space-y-8 divide-y divide-gray-200">
      <div>
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Citibot Search Job
          </h3>
          <p class="mt-1 text-sm text-gray-500">
            A search job is a repeatable job that crawls a site and generates
            search documents
          </p>
        </div>
        {#if error}
          <!-- This example requires Tailwind CSS v2.0+ -->
          <div class="rounded-md bg-red-50 p-4">
            <div class="flex">
              <div class="flex-shrink-0">
                <!-- Heroicon name: x-circle -->
                <svg
                  class="h-5 w-5 text-red-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
              <div class="ml-3">
                <h3 class="text-sm font-medium text-red-800">
                  An error occured trying to save form.
                </h3>
                <div class="mt-2 text-sm text-red-700">
                  <ul class="list-disc pl-5 space-y-1">
                    <li>Description is required!</li>
                    <li>Website is required!</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        {/if}

        <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-4">
            <label
              for="description"
              class="block text-sm font-medium text-gray-700"
            >
              Description
            </label>
            <div class="mt-1">
              <input
                bind:value={description}
                id="description"
                name="description"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-4">
            <label
              for="identifier"
              class="block text-sm font-medium text-gray-700"
            >
              Website
            </label>
            <div class="mt-1">
              <input
                bind:value={website}
                id="website"
                name="website"
                type="text"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              />
            </div>
          </div>
          <div class="sm:col-span-4">
            <label for="script" class="block text-sm font-medium text-gray-700">
              Code
            </label>
            <div>
              Enter the code that will be evaluated on every page to capture the
              title and content. example:
              <pre
                class="border rounded-md bg-gray-600 p-4 my-8"><code class="language-javascript">{`let content = '';
document.querySelectorAll('main p').forEach(el => 
  content = content.concat('\\n\\n', el.textContent));

return { title: document.title, content };
`}</code></pre>
            </div>
            <div class="mt-1">
              <textarea
                bind:value={script}
                id="script"
                name="name"
                type="text"
                rows="10"
                autocomplete="off"
                class="shadow-sm focus:ring-citibot focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md font-mono bg-black text-white"
              />
            </div>
          </div>
          <div class="sm:col-span-4">
            <label for="search" class="block text-sm font-medium text-gray-700">
              <input
                type="checkbox"
                bind:checked={search}
                id="search"
                name="search"
              />
              Auto add results to search index
            </label>
          </div>

          <div class="sm:col-span-6">
            <label
              for="account"
              class="block text-sm font-medium text-gray-700"
            >
              Account
            </label>
            <div class="mt-1">
              {#if editMode}
                {account}
              {:else}
                <select id="account" bind:value={account}>
                  <option>Select Account</option>
                  {#await accounts(url) then accounts}
                    {#each accounts as account}
                      <option value={account.identifier}>{account.name}</option>
                    {/each}
                  {/await}
                </select>
              {/if}
            </div>
            <p class="mt-2 text-sm text-gray-500">
              Account to attach to search documents
            </p>
          </div>

          <div class="sm:col-span-6">
            <label
              for="frequency"
              class="block text-sm font-medium text-gray-700"
            >
              Frequency
            </label>
            <div class="mt-1">
              <select id="frequency" bind:value={frequency}>
                <option>Select Interval</option>
                <option value="manual">On Demand</option>
                <option value="daily">Daily</option>
              </select>
            </div>
            <p class="mt-2 text-sm text-gray-500">Job Frequency</p>
          </div>
        </div>
      </div>
    </div>

    <div class="pt-5">
      <div class="flex justify-end">
        <a
          href="/jobs"
          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Cancel
        </a>
        <button
          type="submit"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
        >
          Save
        </button>
      </div>
    </div>
  </form>
</Shell>
