<script>
  import Shell from "./Shell.svelte";
  import { getAccount, publishAccount } from "./api.js";
  import { router } from "tinro";

  const url = import.meta.env.VITE_APP_API;
  const id = router.meta().params.id;

  function doPublishAccount(id) {
    return function () {
      publishAccount(url, id).then((res) => {
        if (res.ok) {
          router.goto("/accounts");
        }
      });
    };
  }
</script>

<Shell title="Account">
  <div class="flex justify-end">
    <a
      href="/accounts"
      class="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
      >Return to Accounts</a
    >
  </div>
  {#await getAccount(url, id)}
    <p>Loading account...</p>
  {:then account}
    <div class="my-8">
      <h1 class="text-4xl">{account.name}</h1>
      <h2 class="mt-8 text-2xl">Identifier: {account.identifier}</h2>
      <p class="my-8">{account.description}</p>

      <button
        on:click|preventDefault={doPublishAccount(account.id)}
        class="px-4 py-1 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-2 md:text-lg md:px-4"
        >Publish Account</button
      >
      <blockquote
        class="border border-gray-50 p-4 my-4 ml-8 text-sm italic bg-gray-50"
      >
        By clicking publish account, you will kick off a scheduled job to
        publish all indexed search documents to the S3 bucket for the Kendra
        Search Index.
      </blockquote>
      <a
        href="/accounts/{account.id}/edit"
        class="px-4 py-1 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-2 md:text-lg md:px-4"
        >Edit Account</a
      >
    </div>
  {/await}
</Shell>
