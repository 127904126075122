<script>
  import Shell from "./Shell.svelte";
  import { docs, accounts, deleteDoc } from "./api.js";
  import { pathOr } from "ramda";
  const url = import.meta.env.VITE_APP_API;

  let account = localStorage.getItem("account");
  let status = localStorage.getItem("status") || "index";

  $: doDocList = getDocList();

  async function getDocList() {
    if (!account) {
      return await docs(url, status);
    } else {
      return await docs(url, status, account);
    }
  }

  function filter() {
    localStorage.setItem("account", account);
    localStorage.setItem("status", status);
    doDocList = getDocList();
  }

  function clearFilter() {
    localStorage.removeItem("account");
    localStorage.removeItem("status");
    account = null;
    status = null;
    doDocList = getDocList();
  }

  function archive(id) {
    return async function () {
      if (confirm("Are you sure?")) {
        await deleteDoc(url, id);
        doDocList = getDocList();
      }
    };
  }
</script>

<Shell title="Documents">
  <div class="mt-5 relative">
    <p class="my-8">
      Use the documents page to manage search documents to refine citibot search
      results.
    </p>
    {#await accounts(url)}
      <div>Fetching accounts...</div>
    {:then accounts}
      <div>
        <label for="account">Filter by Account: </label>
        <select id="account" bind:value={account}>
          <option>select</option>
          {#each accounts as account}
            <option value={account.identifier}>{account.name}</option>
          {/each}
        </select>
        <label for="status">Status: </label>
        <select id="status" bind:value={status}>
          <option value="index">index</option>
          <option value="new">new</option>
          <option value="archive">archive</option>
        </select>
        <button
          on:click={filter}
          class="ml-4 px-4 py-2 border border-transparent font-medium rounded-md text-white bg-citibot"
          >Filter</button
        >
        <button
          on:click={clearFilter}
          class="ml-4 px-4 py-2 border font-medium rounded-md text-citibot bg-white"
        >
          Clear
        </button>
      </div>
    {/await}
    <div class="text-right rounded md-shadow">
      <a
        class="px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-citibot hover:bg-blue-200 md:py-4 md:text-lg md:px-10"
        href="/documents/new">Add New Document</a
      >
    </div>
  </div>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="mt-8 flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div class="overflow-hidden sm:rounded-lg divide-y">
          {#await doDocList}
            <div>Reading Documents...</div>
          {:then documents}
            {#each documents as document}
              <!-- This example requires Tailwind CSS v2.0+ -->
              <div class="mt-5 pt-5 flex">
                <div class="w-full mb-5">
                  <h4 class="text-lg font-bold">{document.Title}</h4>
                  <!--
                <p class="mt-1">
                  {document.Blob}
                </p>
                -->
                  <p class="mt-5 flex-auto">
                    <b>Source</b>
                    {pathOr(
                      "https://url-goes-here",
                      ["Attributes", "_source_uri"],
                      document
                    )}
                  </p>
                  <div class="flex mt-5">
                    <p class="flex-auto">
                      <b>Account</b>
                      {pathOr(
                        "8885559999",
                        ["Attributes", "account"],
                        document
                      )}
                    </p>
                    <p class="flex-auto">
                      <b>Document Status</b>
                      {document.State}
                    </p>
                    <p>
                      <a
                        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
                        href="/documents/{document.id}/edit">Edit</a
                      >
                      {#if document.State === "new"}
                        <a
                          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
                          href="/documents/{document.id}/review">Review</a
                        >
                      {:else if document.State === "index"}
                        <button
                          class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
                          on:click={archive(document.id)}>Archive</button
                        >
                      {/if}
                    </p>
                  </div>
                </div>
              </div>
            {/each}
          {:catch error}
            <p style="color: red">{error.message}</p>
          {/await}
        </div>
      </div>
    </div>
  </div>
</Shell>
