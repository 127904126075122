import { user } from './auth.js'
import { over, lensPath } from 'ramda'

let token

user.subscribe(u => {
  if (u) {
    console.log('token', token)
    token = u.token
  }
})

export const logs = (url, date, status, label) => {
  let params = new URLSearchParams()
  if (date) { params.append('date', date) }
  if (status !== 'ALL') { params.append('status', status) }
  if (label !== 'ANY') { params.append('label', label) }

  return fetch(`${url}/logs?${params.toString()}`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json())
    .catch(err => ({ ok: false, message: err.message }))
}

export const accounts = (url) => fetch(url + '/accounts', {
  headers: {
    Authorization: `Bearer ${token}`
  }
})
  .then(res => res.json())
  .then(res => res.docs)

export const publishAccount = (url, account) => fetch(`${url}/accounts/${account}/_publish`, {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const postAccount = (url, account) => fetch(url + '/accounts', {
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  method: 'POST',
  body: JSON.stringify(account)
})
  .then(res => res.json())

export const getAccount = (url, id) => fetch(url + '/accounts/' + id, {
  headers: {
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const putAccount = (url, id, account) => fetch(url + '/accounts/' + id, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  body: JSON.stringify(account)
})
  .then(res => res.json())

export const jobs = (url) => fetch(url + '/jobs', {
  headers: {
    Authorization: `Bearer ${token}`
  }
})
  .then(res => res.json())
  .then(res => res.docs)

export const postJob = (url, job) => fetch(url + '/jobs', {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  body: JSON.stringify(job)
}).then(res => res.json())

export const getJob = (url, id) => fetch(url + '/jobs/' + id, {
  headers: {
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const putJob = (url, id, job) => fetch(url + '/jobs/' + id, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  body: JSON.stringify(job)
}).then(res => res.json())

export const deleteJob = (url, id) => fetch(url + '/jobs/' + id, {
  method: 'DELETE',
  headers: {
    authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const docs = (url, status, account) => {
  if (account) {
    url = `${url}/docs?State=${status}&Attributes.account=${account}&limit=1000`
  } else {
    url = `${url}/docs?State=${status}&limit=1000`
  }
  return fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }).then(res => res.json()).then(res => res.docs)
}

export const postDoc = (url, doc) => fetch(url + '/docs', {
  method: 'POST',
  headers: {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  },
  body: JSON.stringify(doc)
}).then(res => res.json())

export const getDoc = (url, id) => fetch(url + '/docs/' + id, {
  headers: {
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const putDoc = (url, id, doc) => fetch(url + '/docs/' + id, {
  method: 'PUT',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  },
  body: JSON.stringify(doc)
}).then(res => res.json())

export const deleteDoc = (url, id) => fetch(url + '/docs/' + id, {
  method: 'DELETE',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const crawl = (url, id) => fetch(`${url}/jobs/${id}/_crawl`, {
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  }
}).then(res => res.json())

export const search = (url, q, account, faq) => {
  let body = { query: q, filter: { account: account } }
  if (faq !== "-1") {
    body = over(lensPath(['filter', 'faq']),
      _ => faq === "1" ? 'true' : 'false'
      , body)
  }
  return fetch(`${url}/search`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  }).then(res => res.json())

}