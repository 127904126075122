<script>
  import { onMount } from "svelte";
  import { createEventDispatcher } from "svelte";
  export let accounts = [];
  const [NOT_SPECIFIED, FAQ_TRUE, FAQ_FALSE] = ["-1", "1", "0"];
  let q, account;
  let faq = NOT_SPECIFIED;

  const dispatch = createEventDispatcher();

  const handleSubmit = () => {
    dispatch("search", { q, account, faq });
  };
</script>

<!--
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
-->
<form
  on:submit|preventDefault={handleSubmit}
  class="space-y-8 divide-y divide-gray-200"
>
  <div class="space-y-8 divide-y divide-gray-200">
    <div>
      <div>
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Search Engine Validator
        </h3>
        <p class="mt-1 text-sm text-gray-500">
          Run Citibots Search Engine to validate search results
        </p>
      </div>

      <div class="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div class="sm:col-span-6">
          <label for="ask" class="block text-sm font-medium text-gray-700">
            Ask
          </label>
          <div class="mt-1">
            <textarea
              bind:value={q}
              id="ask"
              name="ask"
              rows="3"
              class="shadow-sm focus:ring-blue-300 focus:border-citibot block w-full sm:text-sm border-gray-300 rounded-md"
            />
          </div>
          <p class="mt-2 text-sm text-gray-500">
            Ask Citibot Search a question
          </p>
        </div>

        <div class="sm:col-span-6">
          <label for="account" class="block text-sm font-medium text-gray-700">
            Account
          </label>
          <div class="mt-1">
            <select
              bind:value={account}
              id="account"
              name="account"
              autocomplete="off"
              class="shadow-sm focus:ring-citibot focus:border-citibot block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option>Select Account</option>
              {#each accounts as account}
                <option value={account.identifier}>{account.name}</option>
              {/each}
            </select>
          </div>
        </div>
        <div class="sm:col-span-6">
          <label for="faq" class="block text-sm font-medium text-gray-700">
            FAQ
          </label>
          <div class="mt-1">
            <select
              bind:value={faq}
              id="faq"
              name="faq"
              autocomplete="off"
              class="shadow-sm focus:ring-citibot focus:border-citibot block w-full sm:text-sm border-gray-300 rounded-md"
            >
              <option value={NOT_SPECIFIED}>Not Specified</option>
              <option value={FAQ_TRUE}>True</option>
              <option value={FAQ_FALSE}>False</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pt-5">
    <div class="flex justify-end">
      <button
        type="button"
        class="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-citibot hover:bg-blue-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-citibot"
      >
        Search
      </button>
    </div>
  </div>
</form>
